<template>
    <v-container fluid style="padding-bottom:80px">
      <v-row>
        <v-col cols="12">
            <v-breadcrumbs :items="breadcumbs">
                <template v-slot:item="{ item }">
                    <v-breadcrumbs-item
                        :to="item.href"
                        class="text-subtitle-2 crumb-item"
                        :disabled="item.disabled"
                        exact
                    >
                        {{ item.text }}
                    </v-breadcrumbs-item>
                </template>
            </v-breadcrumbs>
        </v-col>
      </v-row>
      <v-row>
            <v-col cols="12">
                <v-card flat>
                    <v-container fluid>
                        <v-row>
                            <v-col xs="6" sm="6" md="2" class="col-6">
                                    <v-autocomplete
                                        prepend-inner-icon="mdi-office-building-outline" clearable dense solo v-model="office_id" :items="offices" item-value="office_id"
                                        :item-text="item => item.office.trim()" label="Office" class="ma-0 pa-0 border-12" hide-details=true :disabled="office_disabled"
                                    ></v-autocomplete>
                                </v-col>
                            <v-col class="col-6" xs="12" sm="5" md="2">
                                <v-autocomplete
                                    solo
                                    clearable
                                    v-model="sales"
                                    :items="saleses"
                                    item-value="sales_id"
                                    item-text="nama"
                                    label="Sales Person"
                                    class="ma-0 pa-0 border-12"
                                    hide-details=true
                                    :disabled="disabled_sales"
                                ></v-autocomplete>
                                <v-progress-linear
                                    :active="loading2"
                                    :indeterminate="loading2"
                                    absolute
                                    bottom
                                    color="deep-purple accent-4"
                                ></v-progress-linear>
                            </v-col>
                            <v-col class="col-6" xs="12" sm="5" md="2">
                                <v-autocomplete
                                    solo
                                    clearable
                                    v-model="Customer" 
                                    :items="datas" 
                                    item-value="customerid" 
                                    item-text="nama"
                                    label="Customer Name"
                                    class="ma-0 pa-0 border-12"
                                    hide-details=true
                                ></v-autocomplete>
                            </v-col>
                            <v-col class="col-6" xs="12" sm="5" md="3">
                                <v-menu
                                    ref="dialog_from"
                                    v-model="modal"
                                    :close-on-content-click="false"
                                    transition="scale-transition"
                                    offset-y
                                    max-width="290px"
                                    min-width="auto"
                                    >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                        solo
                                        v-model="date_from"
                                        label="Date from"
                                        persistent-hint
                                        append-icon="mdi-calendar"
                                        v-bind="attrs"
                                        v-on="on"
                                        class="ma-0 pa-0 border-12"
                                        hide-details=true
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="date_from"
                                        no-title
                                        @input="[modal = false]"
                                    ></v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col class="col-6" xs="12" sm="5" md="3">
                                <v-menu
                                    ref="dialog"
                                    v-model="modal_to"
                                    :close-on-content-click="false"
                                    transition="scale-transition"
                                    offset-y
                                    max-width="290px"
                                    min-width="auto"
                                    >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                        solo
                                        v-model="date_to"
                                        label="Date To"
                                        persistent-hint
                                        append-icon="mdi-calendar"
                                        v-bind="attrs"
                                        v-on="on"
                                        class="ma-0 pa-0 border-12"
                                        hide-details=true
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="date_to"
                                        no-title
                                        @input="[modal_to = false]"
                                    ></v-date-picker>
                                </v-menu>
                            </v-col>
                             <v-col cols="12" xs="12" sm="3" md="2">
                                <v-btn class="border-12" color="info" elevation="2" style="padding: 15px;" @click="search()">Search</v-btn>
                            </v-col>
                            <v-col cols="12">
                                <v-tabs class="mt-3" height="32" v-model="tab">
                                    <v-tab :href="`#tab-1`">
                                        Approval
                                    </v-tab>
                                    <v-tab :href="`#tab-2`">
                                        Summary
                                    </v-tab>                                    
                                </v-tabs>
                                <v-tabs-items v-model="tab">
                                    <v-tab-item value="tab-1">
                                        <v-card outlined>
                                            <v-container fluid>
                                                <v-row>
                                                    <v-col cols="12">
                                                        <v-card-title>Result
                                                            <v-spacer></v-spacer>
                                                            <v-col cols="12" xs="12" sm="12" md="2" class="text-right">
                                                                <v-btn class="mb-4 mr-2 text-white" 
                                                                    color="success" elevation="2" medium>
                                                                    <download-excel
                                                                    class="text"
                                                                    :fetch           = "exportExcel"
                                                                    :fields="headerexcel"
                                                                    :before-generate = "startDownload"
                                                                    :before-finish   = "finishDownload">
                                                                    Export Excel
                                                                    </download-excel> 
                                                                    <!-- <v-icon>mdi-arrow-down-bold-box</v-icon> -->
                                                                </v-btn>
                                                            </v-col>
                                                        </v-card-title>
                                                        <v-data-table
                                                            dense
                                                            :headers="headers"
                                                            :items="purchases"
                                                            class="elevation-1 mt-2"
                                                            :items-per-page="15"
                                                            height="578"
                                                            fixed-header
                                                            :divider="true"
                                                            :light="true"
                                                            group-by="tr_id"
                                                            show-group-by
                                                        >
                                                            <template v-slot:[`item.activity`]="{ item }">
                                                                <div v-html="item.activity">
                                                                </div>
                                                            </template>
                                                            <template v-slot:[`item.distance`]="{ item }">
                                                                {{ item.distance ? new Intl.NumberFormat('en-US', {maximumFractionDigits:2}).format(item.distance) : '' }}
                                                            </template>
                                                            <template v-slot:[`item.speedometer_km`]="{ item }">
                                                                {{ item.speedometer_km ? new Intl.NumberFormat('en-US', {maximumFractionDigits:2}).format(item.speedometer_km) : '' }}
                                                            </template>
                                                            <template v-slot:[`item.fuel_consumed`]="{ item }">
                                                                {{ item.fuel_consumed ? new Intl.NumberFormat('en-US', {maximumFractionDigits:2}).format(item.fuel_consumed) : '' }}
                                                            </template>
                                                            <template v-slot:[`item.fuel_price`]="{ item }">
                                                                <span v-if="salesid == item.salesid">{{ item.fuel_price ? new Intl.NumberFormat('en-US', {maximumFractionDigits:2}).format(item.fuel_price) : '' }}</span>
                                                                <span v-else>???</span>
                                                            </template>
                                                            <template v-slot:[`item.total_entertain`]="{ item }">
                                                                {{ $store.getters.convertToCurrencyNoFrac(item.total_entertain ? item.total_entertain : 0) }}
                                                            </template>
                                                            <template v-slot:[`item.total_parkir`]="{ item }">
                                                                {{ $store.getters.convertToCurrencyNoFrac(item.total_parkir ? item.total_parkir : 0) }}
                                                            </template>
                                                            <template v-slot:[`item.total_tol`]="{ item }">
                                                                {{ $store.getters.convertToCurrencyNoFrac(item.total_tol ? item.total_tol : 0) }}
                                                            </template>
                                                            <template v-slot:[`item.total_other`]="{ item }">
                                                                {{ $store.getters.convertToCurrencyNoFrac(item.total_other ? item.total_other : 0) }}
                                                            </template>
                                                            <template v-slot:[`item.status`]="{ item }">
                                                                <span class="badge badge-info" v-if="item.approve_date === '1900-01-01 00:00:00.000' && item.reject_date === '1900-01-01 00:00:00.000'">Belum di setujui</span>
                                                                <span class="badge badge-success" v-else-if="item.approve_date != '1900-01-01 00:00:00.000' && item.reject_date === '1900-01-01 00:00:00.000'">Sudah di setujui</span>
                                                                <span class="badge badge-danger" v-else-if="item.reject_date != '1900-01-01 00:00:00.000'">Ditolak</span>
                                                            </template>
                                                            <template v-slot:[`item.actions`]="{ item }">  
                                                                <td>
                                                                    <v-tooltip top>
                                                                        <template v-slot:activator="{ on }">
                                                                            <v-icon small class="mr-2" @click="deleteItem(item)" v-on="on">
                                                                                mdi-trash-can
                                                                            </v-icon>
                                                                        </template>
                                                                        <span>Delete</span>
                                                                    </v-tooltip>
                                                                </td>
                                                                <td>
                                                                    <v-tooltip top>
                                                                        <template v-slot:activator="{ on }">
                                                                            <v-icon small class="mr-2" @click="showItem(item)" v-on="on">
                                                                                mdi-google-maps
                                                                            </v-icon>
                                                                        </template>
                                                                        <span>View Map</span>
                                                                    </v-tooltip>
                                                                </td>
                                                                <td>
                                                                    <v-tooltip top>
                                                                        <template v-slot:activator="{ on }">
                                                                            <v-icon small class="mr-2" @click="showFileDetail(item)" v-on="on">
                                                                                mdi-file-image
                                                                            </v-icon>
                                                                        </template>
                                                                        <span>View Image</span>
                                                                    </v-tooltip>
                                                                </td>
                                                                <td>
                                                                    <v-tooltip top>
                                                                        <template v-slot:activator="{ on }">
                                                                            <v-icon small class="mr-2" @click="printItem(item)" v-on="on">
                                                                                mdi-printer
                                                                            </v-icon>
                                                                        </template>
                                                                        <span>Print</span>
                                                                    </v-tooltip>
                                                                </td>
                                                                <!-- <td v-if="item.customerid != 'NEW'">
                                                                    <v-tooltip top>
                                                                        <template v-slot:activator="{ on }">
                                                                            <v-icon small class="mr-2" @click="showdetails(item)" v-on="on">
                                                                                mdi-eye
                                                                            </v-icon>
                                                                        </template>
                                                                        <span>Details</span>
                                                                    </v-tooltip>
                                                                </td> -->
                                                                <td v-if="approve_flag === 'Y' && item.approve_date === '1900-01-01 00:00:00.000' && item.reject_date === '1900-01-01 00:00:00.000'">
                                                                <!-- <td v-if="item.approve_date === '1900-01-01 00:00:00.000' && item.reject_date === '1900-01-01 00:00:00.000'"></td> -->
                                                                    <v-tooltip top>
                                                                        <template v-slot:activator="{ on }" >
                                                                            <v-icon small class="mr-2" @click="showDialogAprv(item)" v-on="on">
                                                                                mdi-check
                                                                            </v-icon>
                                                                        </template>
                                                                        <span>Approval</span>
                                                                    </v-tooltip>
                                                                </td>
                                                            </template>
                                                        </v-data-table>
                                                    </v-col>
                                                </v-row>
                                            </v-container>
                                        </v-card>
                                    </v-tab-item>
                                    <v-tab-item value="tab-2">
                                        <v-card outlined>
                                            <v-container fluid>
                                                <v-row>
                                                    <v-col cols="12">
                                                        <v-card-title>
                                                            Result
                                                            <v-spacer></v-spacer>
                                                            <v-col cols="12" xs="12" sm="12" md="2" class="text-right">
                                                                <v-btn class="mb-4 mr-2 text-white" 
                                                                    color="success" elevation="2" medium>
                                                                    <download-excel
                                                                    class="text"
                                                                    :fetch           = "exportExcelSummary"
                                                                    :fields="headerexcelSummary"
                                                                    :before-generate = "startDownload"
                                                                    :before-finish   = "finishDownload">
                                                                    Export Excel
                                                                    </download-excel> 
                                                                </v-btn>
                                                            </v-col>
                                                        </v-card-title>
                                                        <v-data-table
                                                            fixed-header
                                                            height="490"
                                                            :headers="header_summaries"
                                                            :items="summaries"
                                                            :loading="loading"
                                                            loading-text="Please wait, retrieving data"
                                                            :items-per-page="20"
                                                            class="elevation-1 mt-2"
                                                            :search="search_summary"
                                                        >
                                                            <template v-slot:[`item.distance`]="{ item }">
                                                                {{ $store.getters.convertToCurrencyUs(item.distance) }}
                                                            </template>
                                                            <template v-slot:[`item.fuel_consumed`]="{ item }">
                                                                {{ $store.getters.convertToCurrencyUs(item.fuel_consumed) }}
                                                            </template>
                                                            <template v-slot:[`item.fuel_price`]="{ item }">
                                                                {{ $store.getters.convertToCurrencyNoFrac(item.fuel_price) }}
                                                            </template>
                                                            <template v-slot:[`item.price`]="{ item }">
                                                                {{ $store.getters.convertToCurrencyNoFrac(item.price) }}
                                                            </template>
                                                            <template v-slot:[`item.total_entertain`]="{ item }">
                                                                {{ $store.getters.convertToCurrencyNoFrac(item.total_entertain ? item.total_entertain : 0) }}
                                                            </template>
                                                            <template v-slot:[`item.total_parkir`]="{ item }">
                                                                {{ $store.getters.convertToCurrencyNoFrac(item.total_parkir ? item.total_parkir : 0) }}
                                                            </template>
                                                            <template v-slot:[`item.total_tol`]="{ item }">
                                                                {{ $store.getters.convertToCurrencyNoFrac(item.total_tol ? item.total_tol : 0) }}
                                                            </template>
                                                            <template v-slot:[`item.total_other`]="{ item }">
                                                                {{ $store.getters.convertToCurrencyNoFrac(item.total_other ? item.total_other : 0) }}
                                                            </template>
                                                        </v-data-table>
                                                    </v-col>
                                                </v-row>
                                            </v-container>
                                        </v-card>
                                    </v-tab-item>
                                </v-tabs-items>
                                
                            </v-col>
                            <v-col cols="12" style="padding-bottom: 100px">                                
                                <v-dialog
                                v-model="dialogdetails"
                                max-width="1300px"
                                >
                                    <v-card class="p-2 rounded-l">
                                        <v-card-title class="text-center">
                                            <v-row>
                                                <h4
                                                class="text-center"
                                                style="
                                                font-weight: 600;
                                                color: #053d76;
                                                margin-bottom: 15px;
                                                margin-top: 2px;">
                                                Details Kunjungan
                                                </h4>
                                            <v-divider
                                                style="
                                                border-top: 3px solid #0078d4;
                                                margin-top: 5px;"></v-divider>
                                            </v-row>
                                        </v-card-title>
                                        <v-data-table
                                        fixed-header
                                        height="350"
                                        :headers="headersdetail"
                                        :items="details"
                                        :loading="loading"
                                        loading-text="Please wait, retrieving data"
                                        page-count="10"
                                        class="elevation-1"
                                        >
                                        <template v-slot:[`item.jumlah`]="{ item }">
                                            <v-chip class="ma-2" color="success"> 
                                                {{item.jumlah}} 
                                                </v-chip> 
                                            </template>
                                        </v-data-table> 
                                        <v-card-actions class="text-center mt-10">
                                            <v-row justify="center">
                                                <v-col cols="12" xs="12" sm="3" md="2">
                                                    <v-btn block class="rounded-l text-white"  color="red" @click.stop="dialogdetails=false">Close</v-btn>
                                                </v-col>
                                            </v-row>
                                        </v-card-actions>
                                    </v-card>
                                </v-dialog>
                                <v-dialog v-model="dialog" max-width="1000px">                
                                    <v-card>
                                        <v-card-title>Detail Location</v-card-title>
                                        <v-card-text>
                                            <v-container fluid>
                                                <v-row>
                                                    <v-col cols="12">
                                                        <table cellspacing="0" cellpadding="0" class="container" style="width: 100%;; margin-top: 20px; padding: 20px;border: 1px solid #DDD;font-size: 14px;">
                                                            <tbody>
                                                                <tr>
                                                                    <td>
                                                                        <table width="100%" cellspacing="0" cellpadding="0" style="width: 100%; padding-bottom: 10px;border-bottom: 1px solid #DDD;">
                                                                            <tbody>
                                                                                <tr style="margin-top: 8px; margin-bottom: 8px;">
                                                                                    <td style="width: 80%;">
                                                                                        <div class="d-flex">
                                                                                            <img src="@/assets/srlogo.png" height="60px" alt="">
                                                                                            <!-- <p class="mt-4 ml-4">Sunrise Stell</p> -->
                                                                                        </div>
                                                                                    </td>
                                                                                    <td>
                                                                                        <strong>Sales Activity</strong>
                                                                                    </td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                        <table width="100%" cellspacing="0" cellpadding="0" style="width: 100%; margin: 10px 0;">
                                                                            <tbody>
                                                                                <tr style="margin-top: 8px; margin-bottom: 8px;">
                                                                                    <td style="width:15%; padding-left: 5px;">
                                                                                        Date
                                                                                    </td>
                                                                                    <td style="width:5%">
                                                                                        :
                                                                                    </td>
                                                                                    <td style="width:30%">
                                                                                        {{ this.po_item ? this.po_item.tr_date : '' }}
                                                                                    </td>
                                                                                    <!-- <td style="width:15%; padding-left: 5px;">
                                                                                        Activity
                                                                                    </td> -->
                                                                                    <!-- <td style="width:5%">
                                                                                        :
                                                                                    </td>
                                                                                    <td  style="width:30%">
                                                                                        <div v-html="this.po_item ? this.po_item.activity : ''" style="color: black;"></div>
                                                                                    </td> -->
                                                                                </tr>
                                                                                <tr style="margin-top: 8px; margin-bottom: 8px;">
                                                                                    <td style="width:15%; padding-left: 5px;">
                                                                                        Customer
                                                                                    </td>
                                                                                    <td style="width:5%">
                                                                                        :
                                                                                    </td>
                                                                                    <td style="width:30%">
                                                                                        {{ this.po_item ? this.po_item.namacustomer : '' }}
                                                                                    </td>
                                                                                </tr>
                                                                                <tr style="margin-top: 8px; margin-bottom: 8px;">
                                                                                    <td style="width:15%; padding-left: 5px;">
                                                                                        Address
                                                                                    </td>
                                                                                    <td style="width:5%">
                                                                                        :
                                                                                    </td>
                                                                                    <td colspan="4"  style="width:30%">
                                                                                        {{ this.po_item ? this.po_item.alamat : '' }}
                                                                                    </td>
                                                                                </tr>
                                                                                <tr style="margin-top: 8px; margin-bottom: 8px;">
                                                                                    <td style="width:15%; padding-left: 5px;">
                                                                                        Remark
                                                                                    </td>
                                                                                    <td style="width:5%">
                                                                                        :
                                                                                    </td>
                                                                                    <td colspan="4" style="width:30%">
                                                                                        {{ this.po_item ? this.po_item.remark : '' }}
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td style="width:15%; padding-left: 5px;">
                                                                                        Sales ID
                                                                                    </td>
                                                                                    <td style="width:5%">
                                                                                        :
                                                                                    </td>
                                                                                    <td style="width:30%">
                                                                                        {{ this.po_item ? this.po_item.salesid : '' }} - {{ this.po_item ? this.po_item.nama : '' }}
                                                                                    </td>
                                                                                </tr>
                                                                                <tr style="margin-top: 8px; margin-bottom: 8px;" v-if="this.po_item">
                                                                                    <td style="width:15%; padding-left: 5px;">
                                                                                        Status
                                                                                    </td>
                                                                                    <td style="width:5%">
                                                                                        :
                                                                                    </td>
                                                                                    <td colspan="4"  style="width:30%">
                                                                                        <span class="badge badge-info" v-if="this.po_item.approve_date === '1900-01-01 00:00:00.000' && this.po_item.reject_date === '1900-01-01 00:00:00.000'">Belum di setujui</span>
                                                                                        <span class="badge badge-success" v-else-if="this.po_item.approve_date != '1900-01-01 00:00:00.000' && this.po_item.reject_date === '1900-01-01 00:00:00.000'">Sudah di setujui</span>
                                                                                        <span class="badge badge-danger" v-else-if="this.po_item.reject_date != '1900-01-01 00:00:00.000'">Ditolak</span>
                                                                                    </td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </v-col>
                                                </v-row>
                                                <v-row>
                                                    <v-col cols="12">
                                                        <div id="map_canvas" style="height:432px; width:100%; border: 1px solid grey;"></div>
                                                    </v-col>
                                                </v-row>
                                            </v-container>
                                        </v-card-text>
                                        <v-card-actions>
                                            <v-spacer></v-spacer>
                                            <v-btn color="blue darken-1" text @click="close">Cancel</v-btn>
                                        </v-card-actions>
                                    </v-card>
                                </v-dialog>
                                <v-dialog v-model="dialog_file_doc" max-width="1300px">
                                    <v-card class="p-2 rounded-l">
                                        <v-card-title class="text-center">
                                            <v-row>
                                                <h4
                                                class="text-center"
                                                style="
                                                font-weight: 600;
                                                color: #053d76;
                                                margin-bottom: 15px;
                                                margin-top: 2px;">
                                                Report Sales
                                                </h4>
                                            <v-divider
                                                style="
                                                border-top: 3px solid #0078d4;
                                                margin-top: 5px;"></v-divider>
                                            </v-row>
                                        </v-card-title>
                                        <v-card-text>
                                            <v-container fluid class="pl-5 pr-5 pt-5 pb-4">
                                                <v-row>
                                                    <v-col class="col-12" sm="4" md="4">
                                                        <v-card class="elevation-1 rounded-5">
                                                        <v-card-title></v-card-title>
                                                            <v-card-text class="mt-15" v-if="imageUrl">
                                                                <v-img :src=imageUrl ></v-img>
                                                            </v-card-text>
                                                            <v-card-text v-else>
                                                                <v-carousel v-model="currentSlide">
                                                                        <v-carousel-item
                                                                            v-for="(image, index) in images" :key="index"
                                                                            :title="image.str2"
                                                                        >
                                                                        <v-img :src=image.image ></v-img>
                                                                        </v-carousel-item>
                                                                </v-carousel>
                                                                <v-row justify="center" class="mt-4">
                                                                    <v-col cols="12" class="text-center">
                                                                        <v-btn class="rounded-l text-white" color="green" @click="addImage()">Add Image</v-btn>
                                                                    </v-col>
                                                                    <v-col cols="12" class="text-center">
                                                                        <h6 v-if="currentSlideCategory">
                                                                            {{ currentSlideCategory }} <span v-if="currentSlideText"> = Rp.  {{ $store.getters.convertToCurrencyUs(currentSlideText) }}</span>
                                                                        </h6>
                                                                    </v-col>
                                                                </v-row>
                                                            </v-card-text>
                                                        </v-card>
                                                    </v-col>
                                                    <v-col class="col-12" sm="8" md="8">
                                                        <v-container fluid>
                                                            <v-col cols="12">
                                                                <h5 class="text-center" 
                                                                style="
                                                                font-weight: 600;
                                                                color: #053d76;
                                                                margin-bottom: 45px;
                                                                margin-top: 2px;"><span>Report Sales Info</span></h5>
                                                            </v-col>
                                                            <v-row>
                                                            <v-col cols="12">
                                                                Status : <span class="badge badge-info" v-if="this.po_item.approve_date === '1900-01-01 00:00:00.000' && this.po_item.reject_date === '1900-01-01 00:00:00.000'">Belum di setujui</span>
                                                                <span class="badge badge-success" v-else-if="this.po_item.approve_date != '1900-01-01 00:00:00.000' && this.po_item.reject_date === '1900-01-01 00:00:00.000'">Sudah di setujui</span>
                                                                <span class="badge badge-danger" v-else-if="this.po_item.reject_date != '1900-01-01 00:00:00.000'">Ditolak</span>
                                                            </v-col>
                                                            <v-col class="col-12" sm="3" md="4">
                                                                <h6 class="blue-lcd mb-1">Sales ID<span class="red--text"></span></h6>
                                                                <v-text-field
                                                                    dense
                                                                    solo
                                                                    clearable
                                                                    v-model="salesid" 
                                                                    disabled 
                                                                ></v-text-field> 
                                                            </v-col>
                                                            <v-col class="col-12" sm="3" md="4">
                                                                <h6 class="blue-lcd mb-1">Sales Name<span class="red--text"></span></h6>
                                                                <v-text-field
                                                                    dense
                                                                    solo
                                                                    clearable 
                                                                    v-model="salesname"
                                                                    disabled
                                                                ></v-text-field> 
                                                            </v-col>
                                                            <v-col class="col-12" sm="3" md="4">
                                                                <h6 class="blue-lcd mb-1">Customer Name<span class="red--text"></span></h6>
                                                                <v-text-field
                                                                    dense
                                                                    solo
                                                                    clearable
                                                                    v-model="namacustomer"
                                                                    disabled 
                                                                ></v-text-field> 
                                                            </v-col>
                                                            <v-col class="col-12" sm="3" md="4">
                                                                <h6 class="blue-lcd mb-1">Address<span class="red--text"></span></h6>
                                                                <v-text-field
                                                                    dense
                                                                    solo
                                                                    clearable
                                                                    v-model="alamat"
                                                                    disabled
                                                                ></v-text-field> 
                                                            </v-col>
                                                            <v-col class="col-12" sm="3" md="4">
                                                                <h6 class="blue-lcd mb-1">City<span class="red--text"></span></h6>
                                                                <v-text-field
                                                                    dense
                                                                    solo
                                                                    clearable
                                                                    v-model="city"
                                                                    disabled
                                                                ></v-text-field> 
                                                            </v-col>
                                                            <v-col class="col-12" sm="3" md="4">
                                                                <h6 class="blue-lcd mb-1">Date<span class="red--text"></span></h6>
                                                                <v-text-field
                                                                    dense
                                                                    solo
                                                                    clearable 
                                                                    v-model="tr_date"
                                                                    disabled
                                                                ></v-text-field> 
                                                            </v-col>
                                                            <v-col class="col-12" sm="3" md="4">

                                                                <h6 class="blue-lcd mb-1">Distance<span class="red--text"></span></h6>
                                                                <v-text-field
                                                                    dense
                                                                    solo
                                                                    clearable
                                                                    v-model="distance"
                                                                    disabled
                                                                ></v-text-field> 
                                                            </v-col>
                                                            <v-col class="col-12" sm="3" md="4">
                                                                <h6 class="blue-lcd mb-1">Fuel (L)<span class="red--text"></span></h6>
                                                                <v-text-field
                                                                    dense
                                                                    solo
                                                                    clearable
                                                                    v-model="fuel_consumed"
                                                                    disabled
                                                                ></v-text-field> 
                                                            </v-col>
                                                            <v-col class="col-12" sm="3" md="4">
                                                                <h6 class="blue-lcd mb-1">Fuel Price<span class="red--text"></span></h6>
                                                                <v-text-field
                                                                    dense
                                                                    solo
                                                                    clearable 
                                                                    v-model="fuel_price"
                                                                    disabled
                                                                ></v-text-field> 
                                                            </v-col>

                                                            <v-col class="col-12" sm="3" md="10">
                                                                <h6 class="blue-lcd mb-1">Lt Location<span class="red--text"></span></h6>
                                                                <v-text-field
                                                                    dense
                                                                    solo
                                                                    clearable 
                                                                    v-model="lt_location"
                                                                    disabled
                                                                ></v-text-field> 
                                                            </v-col>
                                                            <div class="col-lg-12 ">
                                                                <h6 class="blue-lcd mb-1">Remark<span class="red--text"></span></h6>
                                                                <v-textarea v-model="remark" label="" disabled clearable outlined></v-textarea>
                                                            </div>
                                                        </v-row>
                                                    </v-container>
                                                </v-col>
                                            </v-row>
                                            </v-container>
                                        </v-card-text>
                                        <v-card-actions class="text-center">
                                            <v-spacer></v-spacer>
                                            <v-btn class="rounded-l text-white"  color="red" @click="dialog_file_doc=false">Close</v-btn>
                                        </v-card-actions>
                                    </v-card>
                                </v-dialog>

                                <v-dialog
                                v-model="dialogPrint"
                                persistent
                                fullscreen
                                hide-overlay
                                transition="dialog-bottom-transition"
                                style="border-radius: 0 !important"
                                >
                                    <v-card class="p-2 rounded-l">
                                        <v-card-title class="text-center">
                                            <v-row>
                                                <v-col cols="12">
                                                    <div class="d-flex">
                                                        Print Sales Activity
                                                        <v-spacer></v-spacer>
                                                        <v-btn class="rounded-l text-white" color="success" @click="printTicket()">
                                                            <v-icon small class="mr-2">
                                                                mdi-printer
                                                            </v-icon>
                                                            Print
                                                        </v-btn>
                                                        <v-btn
                                                            class="mx-2"
                                                            fab
                                                            x-small
                                                            color="#e83e8c;"
                                                            @click="[dialogPrint = false]"
                                                        >
                                                            <v-icon dark>
                                                                mdi-close
                                                            </v-icon>
                                                        </v-btn>
                                                    </div>
                                                </v-col>
                                                <v-divider></v-divider>
                                            </v-row>
                                        </v-card-title>
                                        <v-card-text>
                                            <v-row align="center" justify="center" class="mt-4">
                                                <div style="width: 960px;" id="divPrintSalesActivityKbt">
                                                    <v-container fluid>
                                                        <v-row>
                                                            <v-col cols="12">
                                                                <table cellspacing="0" cellpadding="0" class="container" style="width: 100%;; margin-top: 20px; padding: 20px;border: 1px solid #DDD;font-size: 14px;">
                                                                    <tbody>
                                                                        <tr>
                                                                            <td>
                                                                                <table width="100%" cellspacing="0" cellpadding="0" style="width: 100%; padding-bottom: 10px;border-bottom: 1px solid #DDD;">
                                                                                    <tbody>
                                                                                        <tr style="margin-top: 8px; margin-bottom: 8px;">
                                                                                            <td style="width: 80%;">
                                                                                                <div class="d-flex">
                                                                                                    <img src="@/assets/srlogo.png" height="60px" alt="">
                                                                                                
                                                                                                    <!-- <strong class="mt-4 ml-4">Sales Activity</strong> -->
                                                                                                </div>
                                                                                            </td>
                                                                                            <td>
                                                                                                <strong class="mt-4 ml-4">Sales Activity</strong>
                                                                                                <!-- <p>Sunrise Steel</p> -->
                                                                                            </td>
                                                                                        </tr>
                                                                                    </tbody>
                                                                                </table>
                                                                                <table width="100%" cellspacing="0" cellpadding="0" style="width: 100%; margin: 10px 0;">
                                                                                    <tbody>
                                                                                        <tr style="margin-top: 8px; margin-bottom: 8px;">
                                                                                            <td style="width:15%; padding-left: 5px;">
                                                                                                Date
                                                                                            </td>
                                                                                            <td style="width:5%">
                                                                                                :
                                                                                            </td>
                                                                                            <td style="width:30%">
                                                                                                {{ this.po_item ? this.po_item.tr_date : '' }}
                                                                                            </td>
                                                                                            <td style="width:15%; padding-left: 5px;">
                                                                                                Status
                                                                                            </td>
                                                                                            <td style="width:5%">
                                                                                                :
                                                                                            </td>
                                                                                            <td  style="width:30%">
                                                                                                <span class="badge badge-info" v-if="this.po_item.approve_date === '1900-01-01 00:00:00.000' && this.po_item.reject_date === '1900-01-01 00:00:00.000'">Belum di setujui</span>
                                                                                                <span class="badge badge-success" v-else-if="this.po_item.approve_date != '1900-01-01 00:00:00.000' && this.po_item.reject_date === '1900-01-01 00:00:00.000'">Sudah di setujui</span>
                                                                                                <span class="badge badge-danger" v-else-if="this.po_item.reject_date != '1900-01-01 00:00:00.000'">Ditolak</span>
                                                                                            </td>
                                                                                        </tr>
                                                                                        <tr style="margin-top: 8px; margin-bottom: 8px;">
                                                                                            <td style="width:15%; padding-left: 5px;">
                                                                                                Customer
                                                                                            </td>
                                                                                            <td style="width:5%">
                                                                                                :
                                                                                            </td>
                                                                                            <td style="width:30%">
                                                                                                {{ this.po_item ? this.po_item.namacustomer : '' }}
                                                                                            </td>
                                                                                        </tr>
                                                                                        <tr style="margin-top: 8px; margin-bottom: 8px;">
                                                                                            <td style="width:15%; padding-left: 5px;">
                                                                                                Address
                                                                                            </td>
                                                                                            <td style="width:5%">
                                                                                                :
                                                                                            </td>
                                                                                            <td colspan="4"  style="width:30%">
                                                                                                {{ this.po_item ? this.po_item.alamat : '' }}
                                                                                            </td>
                                                                                        </tr>
                                                                                        <tr style="margin-top: 8px; margin-bottom: 8px;">
                                                                                            <td style="width:15%; padding-left: 5px;">
                                                                                                Remark
                                                                                            </td>
                                                                                            <td style="width:5%">
                                                                                                :
                                                                                            </td>
                                                                                            <td colspan="4" style="width:30%">
                                                                                                {{ this.po_item ? this.po_item.remark : '' }}
                                                                                            </td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                        <td style="width:15%; padding-left: 5px;">
                                                                                            Sales ID
                                                                                        </td>
                                                                                        <td style="width:5%">
                                                                                            :
                                                                                        </td>
                                                                                        <td style="width:30%">
                                                                                            {{ this.po_item ? this.po_item.salesid : '' }} - {{ this.po_item ? this.po_item.nama : '' }}
                                                                                        </td>
                                                                                        </tr>
                                                                                    </tbody>
                                                                                </table>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </v-col>
                                                        </v-row>
                                                        <v-row class="mt-5">
                                                            <v-col cols="12">
                                                                <div id="map_print_canvas" style="height:432px; width:100%; border: 1px solid grey;"></div>
                                                            </v-col>
                                                        </v-row>
                                                    </v-container>
                                                </div>
                                            </v-row>
                                        </v-card-text>
                                    </v-card>
                                </v-dialog>

                                <v-snackbar v-model="snackbar.visible" :color="snackbar.color" :multi-line="snackbar.mode === 'multi-line'" :timeout="snackbar.timeout" :top="snackbar.position === 'top'">
                                    <v-layout align-center pr-4>
                                        <v-icon class="pr-3" dark large>{{ snackbar.icon }}</v-icon>
                                        <v-layout column>
                                            <div>
                                            <strong>{{ snackbar.title }}</strong>
                                            </div>
                                            <div>{{ snackbar.text }}</div>
                                        </v-layout>
                                    </v-layout>
                                    <v-btn v-if="snackbar.timeout === 0" icon @click="snackbar.visible = false">
                                        <v-icon>clear</v-icon>
                                    </v-btn>
                                </v-snackbar>
                                
                                <v-dialog
                                v-model="dialogApprove"
                                max-width="400"
                                persistent
                                >
                                    <v-card>
                                        <v-card-title class="text-h5">
                                        <v-icon>mdi-information-variant</v-icon> Are you sure?
                                        </v-card-title>
                                        <v-card-text>if you are approve this transaction click OK, or reject clik Reject</v-card-text>
                                        <v-card-actions>
                                            <v-btn @click="dialogApprove = false">
                                                Close
                                            </v-btn>
                                            <v-spacer></v-spacer>
                                            <v-btn variant="tonal" color="error" @click="updateStatus('reject')">
                                                Reject
                                            </v-btn>
                                            <v-btn variant="tonal" color="primary" @click="updateStatus('approve')">
                                                Approve
                                            </v-btn>
                                        </v-card-actions>
                                    </v-card>
                                </v-dialog>


                                <v-dialog hide-overlay v-model="dialogImageUpload" max-width="600">
                                    <v-card>
                                        <v-card-title class="text-center mb-0 pb-0">
                                            <v-row>
                                                <v-col cols="12">
                                                    <div class="d-flex">
                                                        <div class="ml-0">
                                                        Upload Image
                                                        </div>
                                                        <v-spacer></v-spacer>
                                                        <v-btn
                                                            class="mx-0"
                                                            x-small
                                                            @click="dialogImageUpload = false"
                                                            plain
                                                        >
                                                            <v-icon>
                                                                mdi-close
                                                            </v-icon>
                                                        </v-btn>
                                                    </div>
                                                </v-col>
                                                <v-divider></v-divider>
                                            </v-row>
                                        </v-card-title>
                                        <v-card-text >
                                            <v-container fluid style="margin-bottom: 0px !important;">                       
                                                <v-row >
                                                    <v-col class="col-12" sm="12" md="12">
                                                        <span class="blue-lcd mb-2 font-12">Category <span class="red--text"><strong>* </strong></span></span>
                                                        <v-autocomplete
                                                            outlined
                                                            dense
                                                            clearable
                                                            v-model="category"
                                                            :items="categories"
                                                            item-value="str1"
                                                            :item-text="item => item.str2.trim()"
                                                            class="ma-0 mt-2 pa-0"
                                                            hide-details=true
                                                        ></v-autocomplete>
                                                    </v-col>
                                                    <v-col class="col-12" sm="12" md="12">
                                                        <span class="blue-lcd mb-2 font-12">Price</span>                                
                                                        <div class="v-input ma-0 pa-0 v-input--dense border-12 theme--light v-text-field v-text-field--single-line v-text-field--solo
                                                        dense v-text-field--is-booted v-text-field--enclosed">
                                                            <div class="v-input__control">
                                                                <div class="v-input__slot">
                                                                    <div class="v-text-field__slot">
                                                                        <money class="" name="length" v-model="price" v-bind="money"></money>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </v-col>
                                                    <v-col class="col-12 pr-1" md="12" v-if="image">
                                                    <img :src="image" class="img" style="width: 100%; border-radius:6px; object-fit: contain;">
                                                    </v-col>
                                                    <v-col class="col-12 pr-1" md="12" v-else>
                                                        <input type="file" name="image" id="image" @change="fileChange" class="form-control" accept="image/*">
                                                    </v-col>
                                                </v-row>
                                            </v-container>
                                        </v-card-text>
                                        <v-card-actions>
                                            <v-spacer></v-spacer>
                                            <v-btn
                                                color="primary"
                                                variant="tonal"
                                                @click="uploadImage()"
                                            >
                                                Save
                                            </v-btn>
                                        </v-card-actions>
                                    </v-card>
                                </v-dialog>


                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>
            </v-col>            
        </v-row>
    </v-container>
</template>
<script>

import { Money } from 'v-money'

export default {
    components: {
        Money
    },
    data() {
        return {
            money: {
				decimal: '.',
				thousands: ',',
				prefix: '',
				suffix: '',
				precision: 0,
				masked: false
			},
            currentSlide: 0,
            snackbar: {
                color: null,
                icon: null,
                mode: null,
                position: "top",
                text: null,
                timeout: 7500,
                title: null,
                visible: false
            },
            breadcumbs: [
                {
                text: 'SMS',
                disabled: false,
                href: '/admin/sms',
                },
                {
                text: 'Sales Activity',
                disabled: true,
                href: 'breadcrumbs_link_2',
                },
                {
                text: 'Activity Visit Report',
                disabled: true,
                href: 'breadcrumbs_link_2',
                },
            ],
            loading: false,
            dialogdetails:false,
            offices: [],
            office: '',
            saleses: [],
            datas:[],
            Customer:'',
            dialogPrint: false,
            sales: '',
            // office_disabled: '',
            date_from: '',
            modal: false,
            date_to: '',
            modal_to: false,
            display: 'none',
            purchases: [],
            headers: [
                { text: 'TR ID', align: 'center', sortable: true, value: 'tr_id' },
                { text: 'Seq', align: 'center', groupable: false, sortable: true, value: 'seq_id' },
                { text: 'Salesman', align: 'center', groupable: false, sortable: true, value: 'salesid' },
                { text: 'Customer', align: 'center', groupable: false, value: 'namacustomer' },
                { text: 'Id Cust', align: 'center', groupable: false, value: 'customerid' },
                { text: 'Address', align: 'center', groupable: false, value: 'alamat' },
                { text: 'City', align: 'center', groupable: false, value: 'city' },
                { text: 'Date', align: 'center', groupable: false, value: 'tr_date' },
                { text: 'Remark', align: 'center', groupable: false, value: 'remark' },
                { text: 'Activity', align: 'center', groupable: false, value: 'activity' },
                { text: 'Distance (Km)', align: 'center', groupable: false, value: 'distance' },
                { text: 'Speedometer (Km)', align: 'center', groupable: false, value: 'speedometer_km' },
                { text: 'Fuel (L)', groupable: false, value: 'fuel_consumed' },
                { text: 'Fuel Price (Rp)', groupable: false, value: 'fuel_price' },
                { text: 'Total Entertain (Rp)', groupable: false, value: 'total_entertain' },
                { text: 'Total Parkir (Rp)', groupable: false, value: 'total_parkir' },
                { text: 'Total TOL (Rp)', groupable: false, value: 'total_tol' },
                { text: 'Total Other (Rp)', groupable: false, value: 'total_other' },
                { text: 'Status', groupable: false, value: 'status' },
                { text: 'Actions', groupable: false,  value: 'actions', sortable: false },
            ],
            headerexcel: {
            'TR ID':'tr_id',
            'Seq':'seq_id',
            'Salesman': 'salesid',
            'Customer': 'namacustomer',
            'Id Cust':'customerid',
            'Address' : 'alamat',
            'City': 'city',
            'Date': 'tr_date',
            'Remark': 'remark',
            'Activity': 'activity',
            'Distance (Km)': 'distance',
            'Speedometer (Km)': 'speedometer_km',
            'Fuel (L)': 'fuel_consumed',
            'Fuel Price': 'fuel_price',
            'Total Entertain (Rp)': 'total_entertain',
            'Total Parkir (Rp)': 'total_parkir',
            'Total TOL (Rp)': 'total_tol',
            'Total Other (Rp)': 'total_other',
            'Status': 'status',
            },
            details: [],
            headersdetail: [
                { text: 'Sales ID', align: 'start',
                sortable: true, value: 'salesid' },
                { text: 'Customer ID', value: 'customerid' },
                { text: 'Alamat', value: 'alamat' },
                { text: 'Nama Customer', value: 'namacustomer' },
                { text: 'Date', value: 'tr_date' },
                { text: 'Kujungan', value: 'jumlah' },
            ],
            totalItems: 10,
            options: {},
            loading2: false,
            loading:false,
            search_detail: '',
            po_items: [],
            po_item: '',
            salesid:'',
            namacustomer:'',
            alamat:'',
            tr_date:'',
            salesname:'',
            remark:'',
            city:'',
            distance: 0,
            office_id:'',
            fuel_consumed: 0,
            fuel_price: 0,
            lt_location:'',
            mill_id: '',
            option_po_items: {},
            loading3: false,
            loading4: false,
            loading5: false,
            totalItem_po_items: 10,
            dialog: false,
            user_approve1: '',
            user_approve2: '',
            dialog_file_doc:false,
            pagination: 1,
            mymap: '',
            data_var: {
                entity_id : 'STARCON',
                appl_id : 'WEBSTARCON'
            },
            salesid: '',
            disabled_sales: false,
            imageUrl: '',
            images: [], 
            approve_flag: 'N',
            dialogApprove: false,
            detail: {},
            tab: 'tab-1',
            header_summaries: [
                { text: 'Date', align: 'start', groupable: false, value: 'tr_date' },
                { text: 'Sales ID ', align: 'start', groupable: false, sortable: true, value: 'salesid' },
                { text: 'Salesman', align: 'start', groupable: false, sortable: true, value: 'nama' },
                { text: 'Total Distance (Km)', align: 'end', groupable: false, sortable: true, value: 'distance' },
                { text: 'Total Fuel Price', align: 'end', groupable: false, sortable: true, value: 'fuel_price' },
                { text: 'Total Entertain (Rp)', groupable: false, value: 'total_entertain' },
                { text: 'Total Parkir (Rp)', groupable: false, value: 'total_parkir' },
                { text: 'Total TOL (Rp)', groupable: false, value: 'total_tol' },
                { text: 'Total Other (Rp)', groupable: false, value: 'total_other' },
                { text: 'Total Price', align: 'end', groupable: false, sortable: true, value: 'price' }
            ],
            headerexcelSummary:{
                'Date':'tr_date',
                'Sales ID':'salesid',
                'Salesman': 'nama',
                'Total Distance (Km)': 'distance',
                'Total Fuel Price': 'fuel_price',
                'Total Entertain (Rp)': 'total_entertain',
                'Total Parkir (Rp)': 'total_parkir',
                'Total TOL (Rp)': 'total_tol',
                'Total Other (Rp)': 'total_other',
                'Total Price': 'price'
            },
            summaries: [],
            search_summary: '',
            office_disabled: false,
            dialogImageUpload: false,
            category: '',
            categories: [],
            image: '',
            price: 0,
        }
    },
    computed: {
        currentSlideText() {
            return this.images[this.currentSlide]?.price || '';
        },
        currentSlideCategory() {
            return this.images[this.currentSlide]?.str2 || '';
        }
    },
    async mounted(){
        this.$store.dispatch('setOverlay', true)
        await this.getSales()
        await this.getEnvConf()
        await this.getOffice()
        await this.GetCustomer()
        await this.getCategory()
        this.$store.dispatch('setOverlay', false)
    },
    methods:{
        getEnvConf(){
            let data = this.data_var;

            this.$store.dispatch("getVariabelUser", { data }).then(res => {

                for (let i = 0; i < res.data.data.length; i++) {
                    if (res.data.data[i]['var_id'] === 'APRV_SALES_ACTIVITY') {
                        if (res.data.data[i]['var_value'] != null) {
                            this.approve_flag = res.data.data[i]['var_value']
                        } else {
                            this.approve_flag = 'N'
                        }
                    }

                    if (res.data.data[i]['var_id'] === 'OFFICE_ID') {
                        if (res.data.data[i]['var_value'] != null) {
                            this.office_id = res.data.data[i]['var_value']
                            if (this.approve_flag == 'Y'){
                                this.getOffice()
                                // console.log("this.office", this.office_id)
                                this.office_disabled = false
                            } else {
                                this.office_disabled = true
                            }
                        } else {
                            this.office_disabled = false
                        }
                    }

                    if (res.data.data[i]['var_id'] === 'SALESID') {
                        if (res.data.data[i]['var_value'] != null) {

                            this.salesid = res.data.data[i]['var_value']
                            this.sales = res.data.data[i]['var_value']
                            if (this.approve_flag == 'Y'){
                                this.getSales()
                                this.disabled_sales = false
                            } else {
                                this.disabled_sales = true
                            }
                        } else {
                            this.disabled_sales = false
                        }
                    }
                }
                // this.getPullData(1,10)
            })
        },
        printTicket(){
            const elem = document.getElementById("divPrintSalesActivityKbt")
            var domClone = elem.cloneNode(true);
    
            var $printSectionSalesActivityKbt = document.getElementById("printSectionSalesActivityKbt");
            
            if (!$printSectionSalesActivityKbt) {
                var $printSectionSalesActivityKbt = document.createElement("div");
                $printSectionSalesActivityKbt.id = "printSectionSalesActivityKbt";
                document.body.appendChild($printSectionSalesActivityKbt);
            }
            
            $printSectionSalesActivityKbt.innerHTML = "";
            $printSectionSalesActivityKbt.appendChild(domClone);
            window.print();
        },
        getOffice(){
            axios.get(`${process.env.VUE_APP_URL}/api/master/starcon/office`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.offices = res.data
            });
        },
        async GetCustomer(){
            await axios.get(`${process.env.VUE_APP_URL}/api/master/starcon/customer`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
               // console.log(res.data)
                this.datas = res.data
            })
            
        },
        async getCategory(){
            await axios.get(`${process.env.VUE_APP_URL}/api/starcon/CustomerVisit/getCategory`, { 
                headers: { 
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                } 
            })
            .then(res => {
                this.categories = res.data
            });
        },
        async showdetails(item){
            console.log(item.salesid)
            this.namacustomer = item.namacustomer
            this.dialogdetails = true
            await axios.get(`${process.env.VUE_APP_URL}/api/starcon/sales_activity/Detail?search=${this.$store.state.text}&namacustomer=${this.namacustomer ? this.namacustomer : ''}`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                // console.log(res.data)
                this.details = res.data
            })
            
        },
        async printItem(item){
            this.po_item = item
            this.dialogPrint = true

            if (this.mymap != '') {
                this.mymap.off();
                this.mymap.remove();
            }

            await axios.get(`https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${this.po_item.sales_latitude}&lon=${this.po_item.sales_longitude}`)
            .then(res => {
                this.loading = false
                var address = res.data.address.village +', '+ res.data.address.state_district +', '+ res.data.address.state;

                this.mymap = L.map('map_print_canvas').setView([this.po_item.sales_latitude, this.po_item.sales_longitude], 13);
        
                L.tileLayer(`https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token=${process.env.TK_SRS}`, {
                    maxZoom: 18,
                    attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, ' +
                        '<a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, ' +
                        'Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
                    id: 'mapbox/streets-v11',
                    tileSize: 512,
                    zoomOffset: -1
                }).addTo(this.mymap);

                L.marker([this.po_item.sales_latitude, this.po_item.sales_longitude],
                    {draggable: true,        // Make the icon dragable
                    //title: 'Hover Text',     // Add a title
                    opacity: 0.5}            // Adjust the opacity
                    )
                    .addTo(this.mymap)
                    .bindPopup('<b>Your Sales Visit location</b><br>'+address)
                    .openPopup();

            });
        },
        startDownload(){
           this.loading = true
           this.disabledExport=true
        },
        finishDownload(){
            this.loading = false
            this.disabledExport=false
        },
        async search(){
            this.$store.dispatch('setOverlay', true)
            // this.pagination = 1
            await this.getPullData()
            await this.getSummary()
            this.$store.dispatch('setOverlay', false)
        },
        close() {
            this.dialog = false
        },
        async showItem(item){
            this.po_item = item
            this.dialog = true
            this.loading = true

            if (this.mymap != '') {
                this.mymap.off();
                this.mymap.remove();
            }

            await axios.get(`https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${this.po_item.sales_latitude}&lon=${this.po_item.sales_longitude}`)
            .then(res => {
                this.loading = false
                var address = res.data.address.village +', '+ res.data.address.state_district +', '+ res.data.address.state;

                this.mymap = L.map('map_canvas').setView([this.po_item.sales_latitude, this.po_item.sales_longitude], 13);
        
                L.tileLayer(`https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token=${process.env.TK_SRS}`, {
                    maxZoom: 18,
                    attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, ' +
                        '<a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, ' +
                        'Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
                    id: 'mapbox/streets-v11',
                    tileSize: 512,
                    zoomOffset: -1
                }).addTo(this.mymap);

                L.marker([this.po_item.sales_latitude, this.po_item.sales_longitude],
                    {draggable: true,        // Make the icon dragable
                    //title: 'Hover Text',     // Add a title
                    opacity: 0.5}            // Adjust the opacity
                    )
                    .addTo(this.mymap)
                    .bindPopup('<b>Your Sales Visit location</b><br>'+address)
                    .openPopup();

            });
        },
        async exportExcel() {
            var start_date = this.date_from ? new Date(this.date_from).toISOString().substr(0, 10).replace(/-/g,"") : ''
            var end_date = this.date_to ? new Date(this.date_to).toISOString().substr(0, 10).replace(/-/g,"") : ''
            // if (this.date_from === '' || this.date_from === null) {
            //         this.snackbar = {
            //         color: "error",
            //         icon: "mdi-alert-circle",
            //         mode: "multi-line",
            //         position: "top",
            //         timeout: 7500,
            //         title: "Error",
            //         text: "Please fill period",
            //         visible: true
            //         };
            //     return false;
            // } else {
                if (this.start_date) {
                    this.start_date = new Date(this.start_date).toISOString().substr(0, 10).replace(/-/g,"")
                } else  {
                    this.start_date = ''
                }

                if (this.end_date) {
                    this.end_date = new Date(this.end_date).toISOString().substr(0, 10).replace(/-/g,"")
                } else  {
                    this.end_date = ''
                }
                const response = await axios.get(`${process.env.VUE_APP_URL}/api/starcon/sales_activity/ExportExcel?search=${this.$store.state.text}&txtOffID=${this.office_id ? this.office_id : ""}&salesid=${this.sales ? this.sales : ""}&namacustomer=${this.Customer ? this.Customer : ""}&start_date=${start_date ? start_date : ""}&end_date=${end_date ? end_date : ""}`, {
                    headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` }
                });
                return response.data.data
                
            // }

        },
        // async getPullData(page = 1, itemsPerPage = 10, search){
        //     this.loading = true

        //     var start_date = this.date_from ? new Date(this.date_from).toISOString().substr(0, 10).replace(/-/g,"") : ''
        //     var end_date = this.date_to ? new Date(this.date_to).toISOString().substr(0, 10).replace(/-/g,"") : ''

        //     await axios.get(`${process.env.VUE_APP_URL}/api/starcon/sales_activity?search=${this.$store.state.text}&txtOffID=${this.office_id ? this.office_id : ""}&salesid=${this.sales ? this.sales : ""}&namacustomer=${this.Customer ? this.Customer : ""}&start_date=${start_date ? start_date : ""}&end_date=${end_date ? end_date : ""}&page=${page > 1 ? page : ''}&pagination=${itemsPerPage}`, { 
        //         headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
        //     })
        //     .then(res => {
        //         this.display = 'block'
        //         this.loading = false
        //         this.purchases = res.data.data
        //     })
        // },
        async getPullData(){
            this.loading = true

            var start_date = this.date_from ? new Date(this.date_from).toISOString().substr(0, 10).replace(/-/g,"") : ''
            var end_date = this.date_to ? new Date(this.date_to).toISOString().substr(0, 10).replace(/-/g,"") : ''

            await axios.get(`${process.env.VUE_APP_URL}/api/starcon/sales_activity?search=${this.$store.state.text}&txtOffID=${this.office_id ? this.office_id : ""}&salesid=${this.sales ? this.sales : ""}&namacustomer=${this.Customer ? this.Customer : ""}&start_date=${start_date ? start_date : ""}&end_date=${end_date ? end_date : ""}`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.display = 'block'
                this.loading = false
                this.purchases = res.data.data
            })
        },
        async getSummary(){
            var start_date = this.date_from ? new Date(this.date_from).toISOString().substr(0, 10).replace(/-/g,"") : ''
            var end_date = this.date_to ? new Date(this.date_to).toISOString().substr(0, 10).replace(/-/g,"") : ''

            await axios.get(`${process.env.VUE_APP_URL}/api/starcon/sales_activity/summary?salesid=${this.sales ? this.sales : ""}&namacustomer=${this.Customer ? this.Customer : ""}&start_date=${start_date ? start_date : ""}&end_date=${end_date ? end_date : ""}`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.summaries = res.data
            })

        },
        async exportExcelSummary(){
            var start_date = this.date_from ? new Date(this.date_from).toISOString().substr(0, 10).replace(/-/g,"") : ''
            var end_date = this.date_to ? new Date(this.date_to).toISOString().substr(0, 10).replace(/-/g,"") : ''

            const response = await axios.get(`${process.env.VUE_APP_URL}/api/starcon/sales_activity/summary?salesid=${this.sales ? this.sales : ""}&namacustomer=${this.Customer ? this.Customer : ""}&start_date=${start_date ? start_date : ""}&end_date=${end_date ? end_date : ""}`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            return response.data

        },
        async getSales(){
            await axios.get(`${process.env.VUE_APP_URL}/api/master/starcon/sales`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.saleses = res.data
            });
        },
        showFileDetail(item){

            this.dialog_file_doc = true
            
            this.po_item = item
            this.namacustomer = item.namacustomer
            this.salesid = item.salesid
            this.remark = item.remark
            this.alamat = item.alamat
            this.city = item.city
            this.salesname = item.nama
            this.tr_date = item.tr_date
            this.lt_location = item.lt_location
            this.item = item.img_url
            // if (item.img_url) {
            //     this.showImageModal(item.img_url)            
            // } else {
            //     this.imageUrl = ''
            // }
            this.images = item.images
            this.distance = item.distance ? parseFloat(item.distance) : 0
            this.fuel_consumed = item.fuel_consumed ? parseFloat(item.fuel_consumed) : 0
            this.fuel_price = item.fuel_price ? parseInt(item.fuel_price) : 0

        },
        showImageModal(imgUrl){

            // $('#imgModal').modal('show')
            this.imageUrl = process.env.VUE_APP_URL + imgUrl

        },
        showDialogAprv(item){
            this.detail = item
            this.dialogApprove = true
        },
        async updateStatus(status){
            this.$store.dispatch('setOverlay', true)
            await axios.post(`${process.env.VUE_APP_URL}/api/starcon/CustomerVisit/approval`, { 
                    status: status,
                    tr_id: this.detail.tr_id,
                    salesid: this.detail.salesid,
                    seq_id: this.detail.seq_id,
                    // mill_id: this.detail.mill_id,
                },
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                    } 
                }
            )
            .then(res => {

                this.$store.dispatch('setOverlay', false)
                Swal.fire({
                    text: 'Approval successfully',
                    icon: 'success',
                })
                this.dialogApprove = false
                this.search()

            })
            .catch(err => {

                this.$store.dispatch('setOverlay', false)
                if(err.response.status == '401'){
                    this.$router.push('/');
                }

            })
        },

        async addImage()
        {

            this.dialog_file_doc = false
            this.dialogImageUpload = true

            console.log(this.po_item.tr_id)


            
        },

        fileChange(e){
            let file = e.target.files[0]
            this.image = ''
            if (!file.type.includes('image/')) {
                this.snackbar = {
                    color: "warning",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Warning",
                    text: "Please select an image file!",
                    visible: true
                };
                return false
            }
            if (typeof FileReader === 'function') {
                const reader = new FileReader()
                reader.onload = (event) => {
                    this.image = event.target.result
                }
                reader.readAsDataURL(file)
            } else {
                this.snackbar = true
                this.text = "Sorry, FileReader API not supported"
                this.color = 'red'
                return
            }            
        },

        deleteItem(id){

            Swal.fire({
                icon: "warning",
                text: "Delete this item?",
                showDenyButton: false,
                showCancelButton: true,
                confirmButtonText: "Delete",
                confirmButtonColor: "#d33",
            }).then((result) => {
                if (result.isConfirmed) {
                    
                    axios.post(`${process.env.VUE_APP_URL}/api/starcon/CustomerVisit/deleteItem`, { 
                            tr_id: id.tr_id,
                            seq_id: id.seq_id
                        },
                        {
                            headers: {
                                'Accept': 'application/json',
                                'Content-Type': 'application/json',
                                'Access-Control-Allow-Origin': '*',
                                Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                            } 
                        }
                    )
                    .then(res => {

                        Swal.fire({
                            text: 'item deleted successfully',
                            icon: 'success',
                        })
                        this.search()

                    })
                    .catch(err => {

                        console.log(err)                    

                    })

                } else if (result.isDenied) {
                    
                }
            })

        },

        async uploadImage()
        {

            await axios.post(`${process.env.VUE_APP_URL}/api/starcon/CustomerVisit/uploadImage`, {
                    category: this.category,
                    price: this.price, 
                    tr_id: this.po_item.tr_id,
                    seq_id: this.po_item.seq_id,
                    image: this.image
                },
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                    } 
                }
            )
            .then(res => {

                this.dialogImageUpload = false
                this.image = ''

                Swal.fire({
                    text: 'image uploaded successfully',
                    icon: 'success',
                })
                this.search()

            })
            .catch(err => {

                console.log(err)                    

            })

        }

    },
    // watch: {
    //     options: {
    //         handler () {
    //             const { page, itemsPerPage } = this.options
    //             if (this.display === 'block') {
    //                 this.getPullData(page, itemsPerPage)
    //             }
    //         },
    //         deep: true,
    //     }
    // }
}
</script>
<style>
@media screen {
  #printSectionSalesActivityKbt {
      display: none;
  }
}

@media print {
  body * {
    visibility:hidden;
  }
  #printSectionSalesActivityKbt, #printSectionSalesActivityKbt * {
    visibility:visible;
  }
  #printSectionSalesActivityKbt {
    position:absolute;
    left:0;
    top:0;
  }
}
</style>